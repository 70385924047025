import { dubaiBounds } from "src/app/utils/service-region-DUBAI";

export const featureList = {

  serviceRegions : {
    3: 'Dubai'
  },
    
    defaultActiveRideTypes : [
        {name: 'Scheduled Ride', code: 'SCHEDULED_RIDE',value:'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/schedule-ride-dubai.png", packageRequired: false, isDropRequired: true, isAirportRide: false, isRailwayRide: false, slotsRequired: true,tncRequired: false, isMultistopRide: false, isExpressRide: false},
        {name: 'Rental Rides', code: 'SCHEDULED_RENTALS', value:'SCHEDULED_RENTALS', isActive: false, isRecurring: false, url: "assets/scheduled-rentals-ride-dubai.png", packageRequired: true, isDropRequired: false, isAirportRide: false, isRailwayRide: false, slotsRequired: true, tncRequired: true, isMultistopRide: false, isExpressRide: false },
        {name: 'Airport Ride', code: 'SCHEDULED_RIDE', value:'AIRPORT_RIDE', isActive: false, isRecurring: false, url: "assets/airport-ride-dubai.png", packageRequired: false, isDropRequired: true, isAirportRide: false, isRailwayRide: false, slotsRequired: true, tncRequired: false, isMultistopRide: false, isExpressRide: false},
        // {name: 'Shared Rides', code: 'SHARED_RIDE', isActive: false, isRecurring: false, url: "assets/schedule-ride-hero-image.png",  packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: true},
        // {name: 'Recurring Rentals', code: 'RECURRING_RENTALS', isActive: false, isRecurring: true, url: "assets/schedule-ride-hero-image.png",  packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: true},
      ],

      rideTypeFeatures :{
        SCHEDULED_RIDE : {isPremiumAirportEnabled: true, isExpressRideEnabled: false, isRecurringRideEnabled: false, rideMetaDataEnabled: false, isSubPlacesEnabled: true, isPremiumCityEnabled: false},
        SCHEDULED_RENTALS : {isPremiumAirportEnabled: false, isExpressRideEnabled: false, isRecurringRideEnabled: false, rideMetaDataEnabled: false, isSubPlacesEnabled: true, isPremiumCityEnabled: false},
      },


    carVariants : [ {carUrl : 'assets/schedule-ride-dubai.png', 
        carLabel: 'Audi E-Tron',
        category: 'CLASSIC',
        extraPoints: [
          {url: 'assets/luggage.svg', label: 'More luggage Space'},
          {url: 'assets/comfort.svg', label: 'Lavish comfort'},
          {url: 'assets/leg.svg', label: 'More leg space'}
          ],
         priceMapId: 0,
         country: 'AE',
         slotsRequired: true,
         isActive: false,
         extraInfo: [''],
         activeZoneId:[3]
        }
       ],
    
    defaultCoordinates: {lat: 25.197525, lng: 55.274288},
    zoneBasedDefaultCoordinates : {3: {lat: 25.197525, lng: 55.274288}},
    
    defaultPaymentModes:{
      'PREPAID':{isActive: true, iconUrl: 'assets/card_icon.svg', desc: 'pay in advance seamlessly', title: 'PrePaid', code: 'PREPAID'},
      'BUSINESS_ACCOUNT':{isActive: false, iconUrl: 'assets/business_wallet_icon.svg', desc: 'pay in advance seamlessly', title: 'PostPaid', code: 'BUSINESS_ACCOUNT'},
      'CASH' : {isActive: false, iconUrl: 'assets/cash_icon.svg', desc: 'pay at the end of the ride', title: 'Cash', code: 'CASH'},
    },

    defaultActivePaymentMode :{isActive: true, iconUrl: 'assets/card_icon.svg', desc: 'pay in advance seamlessly', title: 'PrePaid', code: 'PREPAID'},

    defaultCallingCode: {
      label: 'United Arab Emirates',
      value: '+971',
      flag: "🇦🇪",
      limit: 9
    },

    defaultCurrency : {
      code: 'AED',
      type: 'POSTFIX'
    },

    maxCreateRideTimeStamp : {
      'SCHEDULED_RIDE' : 90,
      'SCHEDULED_RENTALS': 90,
      'RECURRING_RIDE' : 90
    },

    homepage: {
      news: true,
      externalLinks : true,
      explore: true,
      pricing: false,
      serviceRegion: true,
      faq: true,
      regionBasedRideTypes: {3: ['SCHEDULED', 'RENTAL', 'AIRPORT'],
}
    },

    bounds : {3 : dubaiBounds}
}