import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomerDetails, SharedRideCustomerDetails } from '../../types/container.type';
import { CommonService } from '../../getter-setter/common.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';
import { CustomButton, TextAreaDetails } from '../../types/components.type';
import { ButtonTypes, CustomerDetailsTriggerType, Theme } from '../../types/enums';
import { SharedRideCustomerDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { CreateSharedRideSubscriptionService } from '../../subscriptions/create-ride/create-shared-ride-subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerDetailsApiService } from '../../api-services/create-ride/customer-details-api.service';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';

@Component({
  selector: 'shared-ride-customer-details-container',
  templateUrl: './shared-ride-customer-details-container.component.html',
  styleUrls: ['./shared-ride-customer-details-container.component.scss']
})
export class SharedRideCustomerDetailsContainerComponent implements OnInit, OnChanges{


  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Input() riderLocationIndex : number;
  @Input() TriggerType: string;
  @Input() CustomerRiderIndex: number;

  customerDetails: CustomerDetails = {
    phoneNumber: {
      callingCodesList: [],
      callingCodeValue: this._sharedRideCustomerService.getDefaultCallingCode(),
      inputValue: '',
      limit: this._sharedRideCustomerService.getDefaultCallingCode()['limit'],
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      label: 'Phone Number',
      name: 'phone',
      placeholder: `${this._sharedRideCustomerService.getDefaultCallingCode()['limit']}-digit-number`,
      type: 'text',
      isDisabled: false,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    firstName: {
      placeholder: '',
      type: 'text',
      inputValue: null,
      label: "First Name",
      name: 'first-name',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: true,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    },

    lastName: {
      placeholder: '',
      type: 'text',
      inputValue: null,
      label: "Last Name",
      name: 'last-name',
      validation: null,
      isDisabled: true,
      required: false,
      enableTooltip: false,
      tooltipContent: ''
    },

    email: {
      placeholder: '',
      type: 'email',
      inputValue: null,
      label: "E-mail",
      name: 'email',
      validation: {
        isError: false,
        isSuccess: false,
        message: ''
      },
      isDisabled: true,
      required: true,
      enableTooltip: false,
      tooltipContent: ''
    }
  };

  
  pickupNotesDetails: TextAreaDetails = {
    placeholder : "Additional Comments",
    inputValue: '',
    label: "Pickup Notes",
    rows: '6',
    name: 'pickupNotes',
    validation: null,
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Add instructions for driver for faster pickup' 
  };


  cancelCta: CustomButton = {
    label: 'Cancel',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.OUTLINED
  }

  addRiderCta: CustomButton = {
    label: 'Add',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.FILLED
  }


  riderIndex:number = 0
  maxRiderCount = 0;

  constructor(private _common: CommonService,
              private _sharedRideCustomerService: SharedRideCustomerDetailsService,
              private _sharedRideSubscriptionService: CreateSharedRideSubscriptionService,
              private _loader: NgxSpinnerService,
              private _customerApiService: CustomerDetailsApiService,
              private _commonSubscription: CommonSubscriptionService
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    switch(this.TriggerType){
      case CustomerDetailsTriggerType.CREATE:
        this.riderIndex = this._sharedRideCustomerService.getRiderListArrLength() + 1;
        this.addRiderCta.label = 'Add';
        return;
      
      case CustomerDetailsTriggerType.EDIT:
        this.riderIndex = this.CustomerRiderIndex + 1;
        this.addRiderCta.label = 'Update';
        this.fetchCustomerDetails();
        return;
      
      case CustomerDetailsTriggerType.DELETE:
        this.deleteRider();
    }
  }

  ngOnInit(): void {
    this.maxRiderCount = this._common.getMaximumSharedRideSeats();
    this._common.getCallingCodeList().then(res=>this.customerDetails.phoneNumber.callingCodesList = res)
  }


  fetchCustomerDetails(){
    let riderDetails = this._sharedRideCustomerService.getIndexBasedRiderDetails(this.riderIndex - 1, this.riderLocationIndex)
    
    this.customerDetails.phoneNumber.inputValue = riderDetails.phone;
    this.customerDetails.phoneNumber.callingCodeValue.value = riderDetails.callingCode;
    this.customerDetails.firstName.inputValue = riderDetails.firstName;
    this.customerDetails.lastName.inputValue = riderDetails.lastName;
    this.customerDetails.email.inputValue = riderDetails.email;
    this.pickupNotesDetails.inputValue = riderDetails.pickupNotes;

    Object.keys(this.customerDetails).forEach(step => this.customerDetails[step]['isDisabled'] = false);
  }

  async addRiderDetails(){
     // validation
     const promises = Object.keys(this.customerDetails).map(async inpField => {
      if (this.customerDetails[inpField]['validation'] != null) {
          if(inpField == 'phoneNumber'){
            const res = await this._sharedRideCustomerService.checkValidation(inpField, this.customerDetails[inpField]['inputValue'] ,this.customerDetails[inpField]['callingCodeValue']);
            this.customerDetails[inpField]['validation'] = res;
          }
          else{
            const res = await this._sharedRideCustomerService.checkValidation(inpField, this.customerDetails[inpField]['inputValue']);
            this.customerDetails[inpField]['validation'] = res;  
          } 
        }
      else {
        this.customerDetails[inpField]['validation'] = null;
      }
    });
   
    await Promise.all(promises);
   
   let validatorStatus = Object.keys(this.customerDetails).map(inpField => {
      return this.customerDetails[inpField]['validation'] != null ? this.customerDetails[inpField]['validation']['isError'] : false;
    });

    if (!validatorStatus.includes(true)) {

      
      let riderDetails:SharedRideCustomerDetails = {
          phone: this.customerDetails.phoneNumber.inputValue,
          firstName: this.customerDetails.firstName.inputValue,
          lastName: this.customerDetails.lastName.inputValue,
          email: this.customerDetails.email.inputValue,
          callingCode: this.customerDetails.phoneNumber.callingCodeValue.value,
          pickupNotes: this.pickupNotesDetails.inputValue
    }

      if(this.TriggerType == 'CREATE'){
        this._sharedRideCustomerService.addRider(riderDetails, this.riderLocationIndex);
      }

      else{
        this._sharedRideCustomerService.updateRider(riderDetails, this.CustomerRiderIndex ,this.riderLocationIndex)
      }
    
      this._sharedRideSubscriptionService.emitShowAddLocationCtaObserver(true);
      this.closeDialog();
    }
    else {
      return;
    }
  }

  async getInfoFromInputThroughApi(event){
    this.customerDetails.phoneNumber.limit = this.customerDetails.phoneNumber.callingCodeValue.limit;
    this.customerDetails.phoneNumber.placeholder = `${this.customerDetails.phoneNumber.limit}-digit-number`;
    this.clearCustomerDetails();
    if (this.customerDetails.phoneNumber.inputValue != null && this.customerDetails.phoneNumber.callingCodeValue.limit === this.customerDetails.phoneNumber.inputValue.length) {
      let res = await this._sharedRideCustomerService.checkValidation('phoneNumber', this.customerDetails['phoneNumber']['inputValue'] , this.customerDetails['phoneNumber']['callingCodeValue']);
      this.customerDetails.phoneNumber.validation = res;

      if(!this.customerDetails.phoneNumber.validation.isError){
        let contactDetails = {
          callingCodeValue: this.customerDetails.phoneNumber.callingCodeValue.value,
          inputValue: this.customerDetails.phoneNumber.inputValue
        }

        this._loader.show();
        this._customerApiService.fetchCustomerDetails(contactDetails).subscribe(res => {
          this._loader.hide();
          if (res['statusCode'] == 200) {
            if (res['response'] == null) {
              this.customerDetails.phoneNumber.validation = {
                isSuccess: true,
                isError: false,
                message: 'Number Added !!'
              }
              Object.keys(this.customerDetails).forEach(step => this.customerDetails[step]['isDisabled'] = false);
            }

            else {
              Object.keys(this.customerDetails).forEach(step => {
                if( res['response'][step] && (res['response'][step] != null || res['response'][step] != '')){
                  this.customerDetails[step]['inputValue'] = res['response'][step]
                  this.customerDetails[step]['isDisabled'] =  false//step == 'phoneNumber' ? false : true;
                }
                else{
                  this.customerDetails[step]['isDisabled'] = false;
                }  
              });
            }
          }

          else{
            this._commonSubscription.emitAlert({ theme : Theme.ERROR, title: 'Technical Error', message: res['message'], ctaLabel: 'Ok !'});
          }
        })
      }
      else{
        return ;
      }  
      }
  }

  clearCustomerDetails(){
    this.customerDetails.email.inputValue = ''
    this.customerDetails.firstName.inputValue = '',
    this.customerDetails.lastName.inputValue = ''

    this.customerDetails.email.isDisabled = true;
    this.customerDetails.firstName.isDisabled = true;
    this.customerDetails.lastName.isDisabled = true;
  }

  deleteRider(){
    this._sharedRideCustomerService.removeRider(this.CustomerRiderIndex, this.riderLocationIndex); 
  }


  closeDialog(){
    this.closeDialogEvent.emit(true);
  }

}
